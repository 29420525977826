<template>
    <div class="AuxilLedgerList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="auxilToolFram" ref="auxilToolFram">
                    <el-form-item label="条码">
                        <el-input v-model="auxilToolFram.auxCode" placeholder="请输入条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工具名称">
                        <el-input v-model="auxilToolFram.auxName" placeholder="请输入工具名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工具分类">
                        <el-select placeholder="请选择工器具分类名称" v-model="auxilToolFram.classCode">
                            <el-option v-for="(item, index) in assistClassList" :key="index" :label="item.className"
                                :value="item.classCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规格型号">
                        <el-input v-model="auxilToolFram.auxType" placeholder="请输入工具规格型号查询"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="工具编号">
                        <el-input v-model="auxilToolFram.auxNum" placeholder="请输入工器具编号查询"></el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="状态">
                        <el-select placeholder="请选择工器具状态" v-model="auxilToolFram.status">
                            <el-option v-for="toolStatus in toolStatusList" :key="toolStatus.pKey" :label="toolStatus.pValue"
                                :value="toolStatus.pKey"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" @click="$router.push({ name: 'auxilLedger-create' })"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                    <el-button type="primary" @click="handleDown" icon="el-icon-upload2">导出</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="auxiliaryList" border  style="width: 100%; font-size: 13px;" v-loading="loading" :cell-style="bgColor"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                <el-table-column prop="auxName" label="工具名称" align="center">
                </el-table-column>
                <el-table-column prop="auxType" label="工具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="auxNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center" width="80">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>
                <el-table-column prop="expirationDate" label="保质期/有效期（年）" align="center">
                </el-table-column>
                <el-table-column prop="produceDate" label="出厂日期" align="center">
                </el-table-column>
                <el-table-column prop="lastTestDate" label="上次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="nextTestDate" label="下次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="status_text" label="状态" align="center">
                </el-table-column>
                <el-table-column prop="auxPzbz" label="配置标准" align="center">
                </el-table-column>
                <el-table-column prop="auxXypz" label="现有配置" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="延期入库"
                            v-if="scope.row.status === '01' || scope.row.status === '02'"
                            @click="handleDelay(scope.row)">延期入库</el-button>
                        <el-button type="text" size="mini" title="报废" class="delColor"
                            @click="scrap(scope.row)">报废</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="auxilToolFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="auxilToolFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!-- 新增表单 -->
        <el-dialog title="延期入库" :visible.sync="dialogFormVisible" width="500px" :before-close="closeInfo"
            :close-on-click-modal="false">
            <el-form :model="auxiYCRKInfo" :rules="rules" ref="auxiYCRKInfo">
                <el-form-item label="延期入库日期" :label-width="formLabelWidth" prop="predictRkDate">
                    <el-date-picker v-model="auxiYCRKInfo.predictRkDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        type="date" placeholder="选择延期入库日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="延期入库原因" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="auxiYCRKInfo.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('auxiYCRKInfo')">取 消</el-button>
                <el-button  class="btn" @click="sure('auxiYCRKInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import { getAssistClassAll } from '@/api/assistClass.js'
import { searchAuxilInfo, getAuxilStatus, scrapAuxilInfo } from '@/api/auxilInfo.js'
import { rkDelay } from '@/api/auxiliaryCrk.js'
import { downloadExcel } from '@/api/download.js'
export default {
    data() {
        return {
            auxiliaryList: [],
            assistClassList: [],
            toolStatusList: [],
            auxilToolFram: {
                auxCode: null,
                classCode: null,
                auxName: null,
                auxType: null,
                auxNum: null,
                pageNum: 1,
                pageSize: 10,
            },
            auxiYCRKInfo: {
                auxCode: null,
                predictRkDate: null,
                remark: null,
            },
            total: 0,
            loading: false,
            rules: {
                predictRkDate: [{ required: true, message: '请选择预计入库日期' }],
                remark: [{ required: true, message: '请填写延迟入库原因' }],
            },
            dialogFormVisible: false,
            formLabelWidth: '120px'

        }
    },
    mounted() {
        this.loadToolStatus()
        this.loadToolClass()
        this.loadAuxiliaryInfo()
    },
    methods: {

        handleSizeChange(val) {
            this.auxilToolFram.pageSize = val;
            this.auxilToolFram.pageNum = 1;
            this.loadAuxiliaryInfo();
        },
        handleCurrentChange(val) {
            this.auxilToolFram.pageNum = val;
            this.loadAuxiliaryInfo();
        },

        async loadToolClass() {
            await getAssistClassAll().then(res => {
                if (res.code === '000000') {
                    this.assistClassList = res.t
                }
            })
        },
        async loadToolStatus() {
            await getAuxilStatus().then(res => {
                if (res.code === '000000') {
                    this.toolStatusList = res.data
                }
            })
        },

        loadAuxiliaryInfo() {
            this.loading = true
            searchAuxilInfo(this.auxilToolFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.auxiliaryList = res.t.list
                        this.total = res.t.total
                        var count = 1;
                        this.auxiliaryList.forEach((item) => {
                            item.seq = count++
                            switch (item.status) {
                                case '00':
                                    item.status_text = '已入库'
                                    break;
                                case '01':
                                    item.status_text = '已出库'
                                    break;
                                case '02':
                                    item.status_text = '使用未返回'
                                    break;
                                case '03':
                                    item.status_text = '报废'
                                    break;
                                default:
                                    item.status_text = ''
                                    break;
                            }
                        })
                    }
                }, 500)

            })
        },

        bgColor({ row }) {
            let cellStyle = '';
            switch (row.status) {
                case '02':
                    cellStyle = 'background-color: rgb(239, 216, 87);color: #000;'
                    break;
                default:
                    cellStyle = 'background-color: #FFF;color: #000;'
                    break;

            }

            return cellStyle;

        },

        handleEdit({ id }) {
            this.$router.push({
                name: 'auxilLedger-edit',
                params: {
                    id
                }
            })
        },

        handleDetail({ id }) {
            this.$router.push({
                name: 'auxilLedger-detail',
                params: {
                    id
                }
            })
        },
        handleDelay({ auxCode }) {
            this.auxiYCRKInfo.auxCode = auxCode
            this.dialogFormVisible = true
        },

        //提交
        sure(auxiYCRKInfo) {
            this.$refs[auxiYCRKInfo].validate(valid => {
                if (valid) {
                    rkDelay(this.auxiYCRKInfo).then(res => {
                        if (res.code === '000000') {
                            this.$message.success('延迟成功！')
                        }

                        this.$refs[auxiYCRKInfo].resetFields()
                        this.dialogFormVisible = false

                        this.loadAuxiliaryInfo()
                    })

                }
            })
        },

        //报废
        scrap({id,auxName}) {
            this.$confirm('是否报废名称为“'+auxName+'”的辅助工具？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                scrapAuxilInfo(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('报废成功')
                        // this.loadSelect()
                        this.loadAuxiliaryInfo()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })

        },

        //搜索
        handleSearch() {
            this.loadAuxiliaryInfo(this.auxilToolFram);
        },
        //重置
        handleReset() {
            this.auxilToolFram = {};
            this.loadAuxiliaryInfo(this.auxilToolFram);
        },
        //关闭
        closeInfo() {
            this.$refs['auxiYCRKInfo'].resetFields();
            this.dialogFormVisible = false;
        },

        handleDown() {
            this.$fullScreenLoading.show("导出中...");
            
            downloadExcel(this.deviceForm,'/auxiliaryInfo/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // let filename = "自定义文件名称"+ '.xls'
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);

                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                    message: "导出成功~",
                    type: "success",
                });
                }, 500)
                

            })
        },

    }
}
</script>

<style lang="scss" scoped>
.AuxilLedgerList {

    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>